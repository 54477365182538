<template>
  <div>
    <p class="display-1 mt-10 white--text">Szabadság elfogadás</p>
    <v-card class="pb-2 mb-2">
      <p class="pa-2 bgcolor">Új szabadságok</p>
      <v-card-text>
        <v-row>
          <v-combobox
            label="Dolgozo"
            :items="dolgozok"
            item-value="id"
            item-text="name"
            class="mx-3"
            v-model="newSzabDolgozo"
            @change="getNewSzab"
          ></v-combobox>
        </v-row>
        <v-simple-table>
          <thead>
            <tr>
              <th>Dolgozó neve</th>
              <th>Szabadság Dátuma</th>
              <th>Megjegyzés</th>
              <th>Nap típusa</th>
              <th>Szabadság típus</th>
              <th>Elfogadás</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(szab, id) in newSzabs" :key="id">
              <td>{{ szab.name }}</td>
              <td>{{ szab.mettol }}</td>
              <td>{{ szab.megjegyzes }}</td>
              <td>
                <span v-if="szab.felnap == '0'">Teljes nap</span>
                <span v-else>Fél nap</span>
              </td>
              <td>
                <span v-if="szab.maradek == '0'">Rendes nap</span>
                <span v-else>Maradék nap</span>
              </td>
              <td>
                <button @click="elfogadNewSzab(szab)">
                  <v-icon>mdi-check</v-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-card class="pb-2 mb-2">
      <p class="pa-2 bgcolor">Módosított szabadságok</p>
      <v-card-text>
        <v-row>
          <v-combobox
            label="Dolgozo"
            :items="dolgozok"
            item-value="id"
            item-text="name"
            class="mx-3"
            v-model="modifySzabDolgozo"
            @change="getModifySzab"
          ></v-combobox>
        </v-row>
        <v-simple-table>
          <thead>
            <tr>
              <th>Dolgozó neve</th>
              <th>Módosítás típusa</th>
              <th>Dátum</th>
              <th>Megjegyzés</th>
              <th>Félnap</th>
              <th>Elfogadás</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(szab, id) in modifySzabs" :key="id">
              <td v-if="modifySzabDolgozo.name != ''">
                {{ modifySzabDolgozo.name }}
              </td>
              <td v-else>{{ szab.eredeti.name }}</td>
              <td>{{ szab.type }}</td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <td
                    v-on="on"
                    v-bind="attrs"
                    v-if="szab.mire != szab.eredeti.mettol"
                    style="color: red"
                  >
                    {{ szab.mire }}
                  </td>
                  <td v-on="on" v-bind="attrs" v-else>{{ szab.mire }}</td>
                </template>
                <span>{{ szab.mit }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <td
                    v-on="on"
                    v-bind="attrs"
                    v-if="szab.megjegyzes != szab.eredeti.megjegyzes"
                    style="color: red"
                  >
                    {{ szab.megjegyzes }}
                  </td>
                  <td v-on="on" v-bind="attrs" v-else>{{ szab.megjegyzes }}</td>
                </template>
                <span>{{ szab.eredeti.megjegyzes }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <td
                    v-on="on"
                    v-bind="attrs"
                    v-if="szab.felnap != szab.eredeti.felnap"
                  >
                    <v-icon v-if="szab.felnap == '1'">mdi-battery-50</v-icon
                    ><v-icon v-else color="red">mdi-battery</v-icon>
                  </td>
                  <td v-on="on" v-bind="attrs" v-else>
                    <v-icon v-if="szab.felnap == '1'">mdi-battery-50</v-icon
                    ><v-icon v-else>mdi-battery</v-icon>
                  </td>
                </template>
                <span v-if="szab.eredeti.felnap == '1'">
                  <v-icon dark>mdi-battery-50</v-icon>
                </span>
                <span v-else>
                  <v-icon dark>mdi-battery</v-icon>
                </span>
              </v-tooltip>
              <td>
                <button @click="elfogadModifySzab(szab)">
                  <v-icon>mdi-check</v-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <!-- <v-card>
      <p class="pa-2 bgcolor">Összes szabadság</p>
      <v-card-text>
        <v-row>
          <v-col :cols="12" :lg="4" :md="4" :sm="12">
            <v-autocomplete
              label="Dolgozo"
              :items="dolgozok"
              item-value="id"
              item-text="name"
              class="mx-3"
              v-model="selectedDolgozo"
              @change="modifyAllSzab"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col :cols="12" :lg="4" :md="4" :sm="12">
            <v-autocomplete
              label="Év"
              :items="years"
              item-value="value"
              item-text="text"
              class="mx-3"
              v-model="selectedYear"
              @change="modifyAllSzab"
            ></v-autocomplete>
          </v-col>
          <v-col :cols="12" :lg="4" :md="4" :sm="12">
            <v-autocomplete
              label="Hónap"
              :items="months"
              item-value="value"
              item-text="text"
              class="mx-3"
              v-model="selectedMonth"
              @change="modifyAllSzab"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-data-table
          :headers="header"
          hide-default-footer
        ></v-data-table>
      </v-card-text>
    </v-card> -->
    <v-snackbar :color="snackbarColor" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data: () => ({
    snackbarColor: "",
    snackbarText: "",
    snackbar: false,
    dolgozok: [],
    newSzabs: [],
    modifySzabs: [],
    newSzabDolgozo: {
      id: "",
      name: "",
    },
    modifySzabDolgozo: {
      id: "",
      name: "",
    },
    selectedDolgozo: {},
    selectedMonth: {},
    selectedYear: "",
    years: [
      {value: "2024", text: "2024"},
      {value: "2025", text: "2025"},
      {value: "2026", text: "2026"},
      {value: "2027", text: "2027"},
      {value: "2028", text: "2028"},
      {value: "2029", text: "2029"},
      {value: "2030", text: "2030"},
      {value: "2031", text: "2031"},
    ],
    months: [
      {value: "01", text: "Január"},
      {value: "02", text: "Február"},
      {value: "03", text: "Március"},
      {value: "04", text: "Április"},
      {value: "05", text: "Május"},
      {value: "06", text: "Junius"},
      {value: "07", text: "Július"},
      {value: "08", text: "Augusztus"},
      {value: "09", text: "Szeptember"},
      {value: "10", text: "Október"},
      {value: "11", text: "November"},
      {value: "12", text: "December"},
    ],
    allSzab: [],
    header: [
      {text: "Dolgozó neve", value: "dolgozo"},
      {text: "Szabadság dátuma", value: "mettol"},
      {text: "Megjegyzés", value: "megjegyzes"},
      {text: "Félnap", value: "felnap"},
      {text: "Maradék", value: "maradek"},
      {text: "Könyvelt félnap", value: "konyvelt_felnap"},
      {text: "Elfogadott", value: "elfogadott"}
    ]
  }),

  mounted() {
    this.getDolgozok();
    this.getNewAll();
    this.getModAll();
    this.selectedYear = moment().format('YYYY')
  },
  methods: {
    async getAllSzab() {
      try {
        const post = {
          dolgozo: this.selectedDolgozo,
          month: this.selectedMonth,
          year: this.selectedYear
        }
        console.log(post)
        const response = await this.$http.post('/getAllSzab', post);
        this.allSzab = response.szabadsagok
      } catch (e) {
        console.error(e)
      }
    },
    async modifyAllSzab() {
      try {
        this.allSzab = [];
        this.getAllSzab();
      } catch (e) {
        console.error(e)
      }
    },
    async getNewAll() {
      try {
        const szabadsagok = await this.$http.post("/getNewAll");
        this.newSzabs = szabadsagok.szabadsagok;
      } catch (e) {
        console.error(e);
      }
    },
    async getModAll() {
      try {
        const szabadsagok = await this.$http.post("/getModAll");
        this.modifySzabs = szabadsagok.ertesitesek;
      } catch (e) {
        console.error(e);
      }
    },
    async getDolgozok() {
      try {
        const response = await this.$http.post("/getDolgozok");
        this.dolgozok = response.dolgozok;
      } catch (e) {
        console.error(SecurityPolicyViolationEvent);
      }
    },

    async getNewSzab() {
      try {
        const response = await this.$http.post(
          "/getNewSzabs",
          this.newSzabDolgozo
        );
        this.newSzabs = response.szabadsagok;
      } catch (e) {
        console.error(e);
      }
    },

    async elfogadNewSzab(item) {
      try {
        var eventId = "";
        var name = "";
        if (this.newSzabDolgozo.name == "") {
          name = item.name;
        } else {
          name = this.newSzabDolgozo.name;
        }

        const res = await this.$axios.post(
          "https://jelenleti.uninet.hu:8081/insert",
          {
            user: name,
            date: item,
          },
          { headers: { apikey: "EhSnCZ46n1wGn1CendPPto8TjA0v8KgM" } }
        );
        // var res = {
        //   data: {
        //     id: "teszt"
        //   }
        // }
        eventId = "teszt";
        const post = {
          id: item.id,
          eventId: res.data.id,
        };
        const response = await this.$http.post("/elfogadNewSzab", post);
        if (response.status == "OK") {
          this.snackbarColor = "green";
          this.snackbarText = "Sikere elfogadás";
          this.snackbar = true;
        } else {
          (this.snackbarColor = "red"),
            (this.snackbarText = "Sikertelen mentés");
          this.snackbar = true;
        }
        this.getNewAll();
      } catch (e) {
        console.error();
      }
    },

    async getModifySzab() {
      try {
        const response = await this.$http.post(
          "/getModifySzabs",
          this.modifySzabDolgozo
        );
        this.modifySzabs = response.ertesitesek;
      } catch (e) {
        console.error(e);
      }
    },

    async elfogadModifySzab(item) {
      try {
        var user = "";
        if (this.modifySzabDolgozo.name == "") {
          user = item.eredeti;
        } else {
          this.modifySzabDolgozo;
        }
        await this.$axios.post(
          "https://jelenleti.uninet.hu:8081/" + item.type_alias,
          {
            user: user,
            date: item,
          },
          { headers: { apikey: "EhSnCZ46n1wGn1CendPPto8TjA0v8KgM" } }
        );
        const post = {
          id: item,
        };
        const response = await this.$http.post("/elfogadModifySzab", item);
        if (response.status == "OK") {
          this.snackbarColor = "green";
          this.snackbarText = "Sikere elfogadás";
          this.snackbar = true;
        }
        this.getModAll();
      } catch (e) {
        console.error(e);
      }
      //updateelem a ertesitest szabira majd törlöm az értesítést
      //ha a 2 dátum nem egyezik, akkor a törlöm a naptárból és beírom az újat
    },
  },
};
</script>

<style scoped>
.v-list-item--active {
  background-color: red !important;
  color: red !important;
}
</style>
